import * as React from "react"
import { Content, PageLayout, TopNavigation } from '@atlaskit/page-layout';
import "../styles.css";
import NavBar from "../components/navbar";
import BottomBar from "../components/bottomBar";

const TermsOfService = () => {

    const isBrowser = () => typeof window !== "undefined";
  
    return (
      <PageLayout>
            <TopNavigation
                testId="topNavigation"
                id="product-navigation"
                skipLinkTitle="Product Navigation"
                height={60}
                isFixed={false}
            >
                <NavBar/>
            </TopNavigation>
          <Content testId="content">
            <div style={{ width: "100vw", margin: "auto", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}>
              <div style={{ maxWidth: "75%", paddingBottom: 100 }}>
                <h1 id="autocompress-terms-of-service">Autocompress Terms of Service</h1>
                <p>Last Updated: February 02, 2023</p>
                <hr/>
                <p>(These terms are based on the terms maintained by @Automattic at <a href="https://github.com/Automattic/legalmattic">https://github.com/Automattic/legalmattic</a>)</p>
                <p>WELCOME TO AUTOCOMPRESS WHICH IS OWNED AND OPERATED BY HOLD MY COFFEE LTD. (AUTOCOMPRESS, “WE”, “US” or “OUR”). PLEASE READ THESE TERMS AND CONDITIONS OF SERVICE (THESE “TERMS”) CAREFULLY.</p>
                <p>BY ACCESSING OR USING THIS WEBSITE, PURCHASING SERVICES OR CLICKING AT THE TIME OF YOUR REGISTRATION FOR THE SERVICES, YOU ARE ENTERING INTO A BINDING AGREEMENT WITH AUTOCOMPRESS AND AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE TO ALL OF THE TERMS AND CONDITIONS SET FORTH IN THESE TERMS, YOU ARE NOT PERMITTED TO USE THIS WEBSITE OR THE SERVICES.</p>
                <p>These Terms apply to your access to and use of Autocompress, any related websites, and Autocompress’ services, applications and features (collectively, the “Services”). If you are using the Services on behalf of any entity, you represent and warrant that you are authorized to accept these Terms on such entity’s behalf and that such entity agrees to indemnify you and Autocompress for violations of these Terms.</p>
                <p>These Terms contain provisions that govern how claims that you and Autocompress have against each other are resolved (see Dispute Resolution section). These Terms also contain provisions requiring you to resolve certain disputes or claims relating to your use of the Services by binding arbitration, rather than in court. If you do not consent to such terms, you are not permitted to use the Services.</p>
                <p>Autocompress reserves the right to change or modify any of the terms and conditions contained in these Terms (or any policy or guideline of Autocompress) at any time and in its sole discretion by providing notice that these Terms have been modified. Such notice may be provided by posting a notice on the Site, by posting the revised Terms on the Site and revising the date at the top of these Terms or by such other form of notice as determined by Autocompress. Any changes or modification will be effective 30 days after providing notice that these Terms have been modified (the “Notice Period”). Your continued use of the Services following the Notice Period will constitute your acceptance of such changes or modifications. Therefore, you should review these Terms whenever you access the Services and at least every 30 days to make sure that you understand the terms and conditions that will apply to your use of the Services.</p>
                <h2 id="privacy-policy">Privacy Policy</h2>
                <p>Please refer to our Privacy Policy for information on how Autocompress collects, uses and discloses your information when you use our Services.</p>
                <h2 id="registration-data-account-security">Registration Data; Account Security</h2>
                <p>In consideration of your use of the Services, you agree to (a) provide accurate, current and complete information about you as may be prompted by any registration forms on the Services (“Registration Data”); (b) maintain the security of your password and identification; (c) maintain and promptly update the Registration Data, and any other information you provide to Autocompress, to keep it accurate, current and complete; and (d) accept all risks of unauthorized access to the Registration Data and any other information you provide to Autocompress.</p>
                <p>You are responsible for safeguarding the password that you use to access the Services. When you register for the Services, you should use a strong password, including a combination of upper and lower case letters.</p>
                <p>The Services are not intended for users who are under the age of 13. In order to create an account for the Services, you must be 13 years of age or older. If children between the ages of 13 and 18 wish to use the Services, they must be registered by their parent or guardian. By registering, you represent and warrant that you are 13 years of age or older.</p>
                <h2 id="user-content-and-conduct">User Content and Conduct</h2>
                <p>The Services enable you and other users to automatically compress JPG, PNG, GIF, SVG and WEBP files uploaded to the source control software Bitbucket. While using the Service, you agree to not do any of the following:</p>
                <ul>
                <li>Use the Services in any manner that could interfere with, disrupt, negatively affect or inhibit other users from fully enjoying the Services or that could damage, disable, overburden or impair the functioning of the Services in any manner;</li>
                <li>Cheat or utilize unauthorized exploits in connection with the Services;</li>
                <li>Stalk, intimidate, threaten, or otherwise harass or cause discomfort to other users;</li>
                <li>Harvest or otherwise collect information about users, including e-mail addresses, without their consent;</li>
                <li>Use the Services for any illegal or unauthorized purpose or engage in, encourage, or promote any illegal activity, or any activity that violates these Terms; or</li>
                <li>Circumvent or attempt to circumvent any filtering, copy protection mechanisms, security measures or other features Autocompress may from time to time adopt to protect the Service, its users or third parties.</li>
                </ul>
                <p>Any use of the Services in violation of the foregoing violates these Terms and may result in, among other things, termination or suspension of your rights to use the Services. Enforcement of the User Conduct rules set forth in these Terms is solely at Autocompress’ discretion, and failure to enforce such rules in some instances does not constitute a waiver of our right to enforce such rules in other instances. These rules do not create any private right of action on the part of any third party or any reasonable expectation that the Services will not contain any content that is prohibited by such rules.</p>
                <p>Although Autocompress has no obligation to screen, edit or monitor any of the Content posted to the Services, Autocompress reserves the right, and has absolute discretion, to remove, screen or edit any User Content posted or stored on the Services at any time and for any reason without notice, and you are solely responsible for creating backup copies of and replacing any User Content you post or store on the Services at your sole cost and expense.</p>
                <p>You retain all intellectual property rights in and to any User Content that you compress by using Autocompress. Except as otherwise set forth herein, Autocompress does not claim any right, title or interest in and to your User Content.</p>
                <p>You represent and warrant that (a) you own and control all of the rights to the User Content that you post or you otherwise have the right to post such User Content to the Services; (b) the User Content is accurate and not misleading; and (c) use and posting of the User Content you supply does not violate these Terms and will not violate any rights of or cause injury to any person or entity.</p>
                <h3 id="indemnification">Indemnification</h3>
                <p>You agree, at your sole expense, to defend, indemnify and hold us, our service providers and consultants, and their respective directors, employees and agents, harmless from and against any and all actual or threatened suits, actions, proceedings (at law or in equity), claims, damages, payments, deficiencies, fines, judgments, settlements, liabilities, losses, costs and expenses (including, but not limited to, reasonable attorney fees, costs, penalties, interest and disbursements) caused by, arising out of, resulting from, attributable to or in any way incidental to: (a) your conduct; (b) your violation of these Terms or the rights of any third-party; or (c) any content you upload, transmit, distribute, store, create or otherwise publish through the Services.</p>
                <h3 id="disclaimer">Disclaimer</h3>
                <p>EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN A WRITING BY AUTOCOMPRESS, THE SERVICES AND AUTOCOMPRESS MATERIALS CONTAINED THEREIN ARE PROVIDED ON AN “AS IS” OR “AS AVAILABLE” BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. AUTOCOMPRESS DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, (A) IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT AS TO THE SERVICES, INCLUDING THE INFORMATION, CONTENT AND MATERIALS CONTAINED THEREIN; (B) THAT AUTOCOMPRESS MATERIALS IN THE SERVICES OR ANY USER CONTENT ARE ACCURATE, COMPLETE, RELIABLE, CURRENT; (C) THAT THE SERVICES WILL OPERATE WILL OPERATE WITHOUT DOWNTIME, INTERRUPTION, DELAY OR ERROR; OR (D) THAT THE SERVICES OR AUTOCOMPRESS’S SERVERS ARE SECURE, FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. YOU ACKNOWLEDGE AND AGREE THAT YOU HAVE RELIED ON NO WARRANTIES. YOUR USE OF THE SERVICES IS SOLELY AT YOUR OWN RISK.</p>
                <p>Autocompress reserves the right to change any and all content contained in the Services and to modify, suspend or discontinue the Services offered by Autocompress or any features or functionality of the Services at any time without notice and without obligation or liability to you.</p>
                <h3 id="limitation-of-liability">Limitation of Liability</h3>
                <p>IN NO EVENT WILL AUTOCOMPRESS, ITS OFFICERS, DIRECTORS, MEMBERS, EMPLOYEES OR AGENTS BE LIABLE FOR ANY SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO LOSS OF USE, LOSS OF PROFITS OR LOSS OF DATA, WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE) OR OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF OR INABILITY TO USE THE SERVICES, THE USER CONTENT OR AUTOCOMPRESS MATERIALS CONTAINED IN OR ACCESSED THROUGH THE SERVICES, INCLUDING WITHOUT LIMITATION ANY DAMAGES CAUSED BY OR RESULTING FROM RELIANCE BY USER ON ANY INFORMATION OBTAINED FROM AUTOCOMPRESS, OR THAT RESULT FROM MISTAKES, OMISSIONS, INTERRUPTIONS, DELETION OF FILES OR E-MAIL, ERRORS, DEFECTS, VIRUSES, DELAYS IN OPERATION OR TRANSMISSION OR ANY FAILURE OF PERFORMANCE, WHETHER OR NOT RESULTING FROM ACTS OF GOD, COMMUNICATIONS FAILURE, THEFT, DESTRUCTION OR UNAUTHORIZED ACCESS TO AUTOCOMPRESS’S RECORDS, PROGRAMS OR SERVICES.</p>
                <p>IN NO EVENT SHALL THE AGGREGATE LIABILITY OF AUTOCOMPRESS, WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED), PRODUCT LIABILITY, STRICT LIABILITY OR OTHER THEORY, ARISING OUT OF OR RELATING TO THE USE OF OR INABILITY TO USE THE SERVICES OR TO THESE TERMS EXCEED [THE GREATER OF COMPENSATION YOU PAY, IF ANY, TO AUTOCOMPRESS FOR ACCESS TO OR USE OF THE SERVICES OR USD $100.00.</p>
                <p>AUTOCOMPRESS IS NOT LIABLE FOR THE ACTIONS, CONTENT, INFORMATION, OR DATA OF THIRD PARTIES, AND YOU RELEASE US, OUR OFFICERS, DIRECTORS, MEMBERS, EMPLOYEES OR AGENTS FROM ANY CLAIMS AND DAMAGES, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH ANY CLAIM YOU MAY HAVE AGAINST SUCH THIRD PARTIES. IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE §1542, WHICH STATES “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.”</p>
                <h2 id="copyright-policy">Copyright Policy</h2>
                <h3 id="repeat-infringer-policy">Repeat Infringer Policy</h3>
                <p>In accordance with the Digital Millennium Copyright Act (“DMCA”) and other applicable law, Autocompress has adopted a policy of terminating, in appropriate circumstances and at Autocompress’s sole discretion, subscribers or account holders who are deemed to be repeat infringers. Autocompress may also at its sole discretion limit access to the Services and/or terminate the accounts of any users who infringe any intellectual property rights of others, whether or not there is any repeat infringement.</p>
                <h3 id="copyright-complaints">Copyright Complaints</h3>
                <p>If you are a copyright owner, or are authorized to act on behalf of one, or authorized to act under any exclusive right under copyright, please report alleged copyright infringements taking place on or through Autocompress Services by completing the following DMCA Notice of Alleged Infringement and delivering it to Autocompress. You should note that if you knowingly misrepresent in your notification that the material or activity is infringing, you will be liable for any damages, including costs and attorneys’ fees, incurred by us or the alleged infringer as the result of our relying upon such misrepresentation in removing or disabling access to the material or activity claimed to be infringing.</p>
                <p>Upon receipt of the Notice as described below, Autocompress will take whatever action, in its sole discretion, it deems appropriate, including removal of the challenged material from Autocompress Services.</p>
                <h3 id="dmca-notice-of-alleged-infringement-notice-">DMCA Notice of Alleged Infringement (“Notice”):</h3>
                <ul>
                <li>Identify the copyrighted work that you claim has been infringed, or if multiple copyrighted works are covered by this Notice you may provide a representative list of the copyrighted works that you claim have been infringed.</li>
                <li>Identify the material that you claim is infringing (or to be the subject of infringing activity) and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material, including at a minimum, if applicable, the URL of the link shown on Autocompress Site or location in the Services where such material may be found.</li>
                <li>Provide your mailing address, telephone number, and, if available, e-mail address.</li>
                <li>Include both of the following statements in the body of the Notice: (a) &quot;I hereby state that I have a good faith belief that the disputed use of the copyrighted material is not authorized by the copyright owner, its agent or the law (e.g., as a fair use)&quot;; and (b) &quot;I hereby state that the information in this Notice is accurate and, under penalty of perjury, that I am the owner, or authorized to act on behalf of the owner, of the copyright or of an exclusive right under the copyright that is allegedly infringed.&quot;</li>
                <li>Provide your full legal name and your electronic or physical signature.</li>
                <li>Deliver this Notice, with all items completed, to Autocompress by email <a href="mailto:pyron@holdmy.coffee">pyron@holdmy.coffee</a></li>
                </ul>
                <p>If you believe a copyright notice was submitted in error regarding your content, or if you contest the removal of allegedly infringing content, you may submit a counter-notice to Autocompress setting forth your good faith belief that such content was removed in error. In accordance with Sections 512(g)(2) and (3) of the DMCA, such counter-notice must set forth: (a) your full legal name, mailing address, telephone number and electronic or physical signature; (b) identification of the disputed material and its location on the Site or in the Services before removal; (c) a statement under penalty of perjury that the material was removed by mistake or misidentification; and (d) a statement that you consent to the jurisdiction of the Federal District Court where your address is located if your address is located in the U.S. or of the [Insert applicable Federal District Court] if you are located outside the U.S., and that you will accept service process from the person who provided the original complaint. Autocompress will process any counter-notices in accordance with DMCA requirements and its own internal policies and procedures.</p>
                <h3 id="dispute-resolution">Dispute Resolution</h3>
                <p>PLEASE READ THE FOLLOWING PARAGRAPH CAREFULLY BECAUSE IT REQUIRES YOU TO ARBITRATE DISPUTES WITH AUTOCOMPRESS AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM US.</p>
                <p>You and Autocompress agree to arbitrate any dispute arising from these Terms or your use of the Services, except that you and Autocompress are NOT required to arbitrate any dispute in which either party seeks equitable and other relief for the alleged unlawful use of copyrights, trademarks, trade names, logos, trade secrets, or patents. ARBITRATION PREVENTS YOU FROM SUING IN COURT OR FROM HAVING A JURY TRIAL.</p>
                <p>You and Autocompress agree (a) to attempt informal resolution prior to any demand for arbitration; (b) that any arbitration will occur in King County, Washington; (c) that arbitration will be conducted confidentially by a single arbitrator in accordance with the rules of JAMS; and (d) that the state or federal courts in King County, Washington have exclusive jurisdiction over any appeals of an arbitration award and over any suit between the parties not subject to arbitration. Other than class procedures and remedies discussed below, the arbitrator has the authority to grant any remedy that would otherwise be available in court. Any dispute between the parties will be governed by this Agreement and the laws of the State of Washington and applicable United States law, without giving effect to any conflict of laws principles that may provide for the application of the law of another jurisdiction. Whether the dispute is heard in arbitration or in court, you and Autocompress will not commence against the other a class action, class arbitration or other representative action or proceeding.</p>
                <p>You can choose to reject this Agreement to Arbitrate (“opt-out”) by mailing us a written opt-out notice (“Opt-Out Notice”) through email at <a href="mailto:pyron@holdmy.coffee">pyron@holdmy.coffee</a> within 30 after the date you accept these Terms for the first time. The Opt-Out Notice must state that you do not agree to this Agreement to Arbitrate and must include your name, address, phone number and the GitHub username used to log into your account. You must sign the Opt-Out Notice for it to be effective. This procedure is the only way you can opt-out of the Agreement to Arbitrate. If you opt-out of the Agreement to Arbitrate, all other parts of these Terms will continue to apply.</p>
                <h2 id="general">General</h2>
                <p>Notwithstanding any of these Terms, Autocompress reserves the right, without notice and in its sole discretion, to terminate your license to use the Services and to block or prevent future your access to and use of the Services.</p>
                <p>If any provision of these Terms is be deemed unlawful, void or for any reason unenforceable, then that provision will be deemed severable from these Terms and will not affect the validity and enforceability of any remaining provisions.</p>
                <h2 id="questions-contact-information">Questions &amp; Contact Information</h2>
                <p>If you have any questions, concerns, complaints or comments in any way related to your use of the Services, please contact us at <a href="mailto:pyron@holdmy.coffee">pyron@holdmy.coffee</a></p>
              </div>
              <BottomBar/>
            </div>

          </Content>
        </PageLayout>
    )
  }
  
  export default TermsOfService;